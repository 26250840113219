import { getLPExperimentInfo, runLPExperiment } from "./LPExperiment";
import { getPremiumExperimentInfo, runPremiumExperiment } from "./PremiumExperiment";
import { getWelcomeExperimentInfo, runWelcomeExperiment } from "./WelcomeExperiment";

const getRandomNumber = () => {
	let retVal = 0;
	try {
	  const array = new Uint32Array(1);
	  crypto.getRandomValues(array);
	  retVal = array[0];
	} catch(err) {
	  let maxNum = 1000000000000;
	  retVal = Math.floor((Math.random() * maxNum));
	}
	return retVal;
}
const getRandomExpId = () => {
	return getRandomNumber() % 24;
}      

export const getExperimentInfo = (expId) => {

	if(window.location.href?.toLowerCase().indexOf("welcome?p=websafety") > -1) {
		return getWelcomeExperimentInfo(expId);
	} else if(window.location.href?.toLowerCase().indexOf("/websafety/premium") > -1 || window.location.href?.toLowerCase().indexOf("/websafety/pricing") > -1) {
		return getPremiumExperimentInfo(expId);
	} else {
		return getLPExperimentInfo(expId);
	}
}

export const runExperiment = () => {
	let utmX = getSetUtmX();
	//[TODO] Always run the experiment, with reference to the point we reached
	if(window.location.href?.toLowerCase().indexOf("welcome?p=websafety") > -1) {
		setExpIdInUtmParams(utmX);
		runWelcomeExperiment(utmX);
	} else if(window.location.href?.toLowerCase().indexOf("/premium") > -1) {
		setExpIdInUtmParams(utmX);
		runPremiumExperiment(utmX);
	} else {
		runLPExperiment(utmX);
	}
	return utmX;
}

export const getSetUtmX = () => {
	let utmX = -1;
	try {
		let existingUtmX = -1;
		if(document.body.hasAttribute("utm_x")) {
			utmX = parseInt(document.body.getAttribute("utm_x"));
		}
		let utm_params = window.localStorage.getItem('utm_params');
		if(utm_params && utm_params.indexOf("utm_x") > -1) {
			let pairs = utm_params.split("&");
			pairs.map(p => {
				if(p && p.indexOf("utm_x") > -1) {
					let nvs = p.split("=");
					if(nvs && nvs.length > 1 && nvs[1]) {
						existingUtmX = parseInt(nvs[1].trim())
					}
				}
			})
		}
		if(existingUtmX > -1) {
			utmX = existingUtmX;
		}
		//check if utm_x is present in url
		const slparams = new URLSearchParams(window.location.search);
		if(slparams.has("utm_x")) {
			let p_utmX = parseInt(slparams.get("utm_x"));
			if(!isNaN(p_utmX)) {
				utmX = p_utmX;
			}
		}
		if(utmX === -1) {
			utmX = getRandomExpId();
		}
		if(utmX > -1) {
			document.body.setAttribute("utm_x", utmX);
		}
	} catch(err) {
		//console.log("Error happened in parsing getUtmX", err);
	}
	return utmX;
}

//set the expId if not present
export const setExpIdInUtmParams = (expId) => {
	try {
		let utm_params = window.localStorage.getItem('utm_params');
		if(utm_params) {
			if(utm_params.indexOf("utm_x") === -1) {
				utm_params += `&utm_x=${expId}`;
			}
		} else {
			utm_params = `&utm_x=${expId}`;
		}
		window.localStorage.setItem('utm_params', utm_params);
	} catch(err) {
		//do nothing
	}
}
import { GetSetAffiliateId } from "./ApiUtil";
import {getExperimentInfo, getSetUtmX, runExperiment} from "../experiment/Experiment";

const sorigin = (window.location.href.indexOf("http://localhost") > -1  ? 
					"dev" : 
					(window.location.href.indexOf("https://staging.browse.live") > -1 ? "staging" : "prod")
				)
const parseParams = (paramStr, prefix="") => {
	try {
		if(!paramStr) {
			return {};
		}
		let toRet = {};
		let pairs = paramStr.split("&");
		pairs.map(p => {
			if(p) {
				let nvs = p.split("=");
				if(nvs && nvs.length > 0) {
					toRet[`${prefix}${nvs[0].trim()}`] = (nvs.length > 1) ? nvs[1].trim() : "";
				}
			}
		})
		return toRet;
	} catch(err) {
		//do nothing
	}
	return {};
}
const getSetGclid = () => {
	let gclid = "";
	let found = false;
	try {
        //check if gclid is present in url
        const slparams = new URLSearchParams(window.location.search);
        if(slparams.has("gclid")) {
            let p_gclid = slparams.get("gclid");
            if(p_gclid) {
                gclid = p_gclid;
				found = true;
				window.localStorage.setItem('gclid', gclid)
            }
        }
		if(!found) {
			let l_gclid = window.localStorage.getItem('gclid');
			if(l_gclid && l_gclid != null) {
				gclid = l_gclid;
			}
		}
	} catch(err) {
		//console.log("Error happened in parsing getSetGclid", err);
	}
	return gclid;
}
const getInfoDict = () => {
	try {
		let utm_params = window.localStorage.getItem('utm_params');
		let affId = GetSetAffiliateId();
		let infoDict = parseParams(utm_params);
		infoDict.affId = affId;
		infoDict.referrer = (document && document.referrer) ? document.referrer : "";
		//only send experiment name when there is experiment
		// comment out so welcome and uninstall pages also have experiment data
		// if(document.body.hasAttribute("utm_x")) 
		{
			let expInfo = getExperimentInfo(infoDict.utm_x);
			if(expInfo && expInfo.isExpRunning) {
				infoDict["utm_xd"] = expInfo.details;
				infoDict["utm_xm"] = expInfo.name;
			}
		}
		//url localhost/staging.browse.live
		infoDict["origin"] = sorigin;
		return infoDict;
	} catch(err) {
		//do nothing
	}
	return {};
}
const sendSiteAnalytics = (jsonData) => {
	//check if there is query data
	try {
		if(jsonData && jsonData.AnalyticsType) {
			var xhttp = new XMLHttpRequest();
			xhttp.withCredentials = false;
			xhttp.open("POST", "https://anonymous.browse.live/blur/siteanalytics", true);
			xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
			xhttp.send(JSON.stringify(jsonData));
		}
	} catch(err) {
		//it should never happen but we should not hold anything for this
		//console.log("bad parameter", err);
	}
}

export const sendPageView = (page, includeUtm=false, category="LP", action="PageView") => {
	try {
		let infoDict = getInfoDict();
		var jsonData = {AnalyticsType: "Browse", EventCategory: category, EventAction: action, EventLabel: page, InfoDict: infoDict};
		sendSiteAnalytics(jsonData);
	} catch(ex) {
		//dothing
	}
}
export const browseAdClick = (adSource, adMedium, adCampaign) => {
	try {
		let exUtmParams = window.localStorage.getItem('utm_params');
		exUtmParams = exUtmParams ? exUtmParams : "";
		let utm_params = `utm_source=${adSource}&utm_medium=${adMedium}&utm_campaign=${adCampaign}`;
		let utm_x=getSetUtmX();
		if(utm_x > -1) {
			utm_params += `&utm_x=${utm_x}`;
		}
		window.localStorage.setItem('utm_params', utm_params);
	
		let infoDict = getInfoDict();
		let oldutmDict = parseParams(exUtmParams, "old_");
		infoDict = {...infoDict, ...oldutmDict};
		var jsonData = {AnalyticsType: "Browse", EventCategory: "InstallAd", EventAction: "InstallClick", EventLabel: `/${adCampaign}/browse/ad`, InfoDict: infoDict};
		sendSiteAnalytics(jsonData);
	} catch(ex) {
		//dothing
	}
}
export const UTMCheck = (eventCategory='LP') => {
	try {
		const slparams = new URLSearchParams(window.location.search);
		//check if UTM sources are present
		let paramArr = [];
		for(let param of slparams.keys()) {
			if(param && param.toLowerCase().indexOf("utm_") > -1 && param.toLowerCase() != "utm_x") {
				//this is a utm params
				paramArr.push(`${param}=${slparams.get(param)}`);
			}
		}
		let paramstr = paramArr.join("&");
		if(paramArr.length === 0) {
			if(slparams.has("affiliate") && slparams.has("subscription")) {
				paramstr = `utm_source=${slparams.get("affiliate")}&utm_medium=affiliate&utm_campaign=${slparams.get("subscription")}`;
			} else {
				paramstr = `utm_source=browse.live&utm_medium=organic&utm_campaign=lp`;
			}
		}
		let utm_x=runExperiment();
		if(utm_x > -1) {
			paramstr += `&utm_x=${utm_x}`;
		}

		//now get the existing param
		let exUtmParams = window.localStorage.getItem('utm_params');
		exUtmParams = exUtmParams ? exUtmParams : "";
		window.localStorage.setItem('utm_params', paramstr);
		
		let infoDict = getInfoDict();
		let oldutmDict = parseParams(exUtmParams, "old_");
		infoDict = {...infoDict, ...oldutmDict};
		
		//get set the gclid
		let gclid = getSetGclid();
		infoDict["gclid"] = gclid;

		//send the event
		var jsonData = {AnalyticsType: "Browse", EventCategory: eventCategory, EventAction: "UTMCheck", EventLabel: "UTM Check", InfoDict: infoDict};
		sendSiteAnalytics(jsonData);
	} catch(ex) {
		//dothing
	}
}

export const linkClick = (forSection, sectionDetail, isInstall=false, category="LP") => {
	try {
		if(!forSection || !sectionDetail) {
			return;
		}
		let label = "/lp/" + forSection + "/" + sectionDetail;
		let action = isInstall ? "InstallClick" : "Click";
		let infoDict = getInfoDict();
		var jsonData = {AnalyticsType: "Browse", EventCategory: category, EventAction: action, EventLabel: label, InfoDict: infoDict};
		sendSiteAnalytics(jsonData);			
	} catch(ex) {
		//dothing
	}
}

export const sendEvent = (action, category, label) => {
	try {
		let infoDict = getInfoDict();
		var jsonData = {AnalyticsType: "Browse", EventCategory: category, EventAction: action, EventLabel: label, InfoDict: infoDict};
		sendSiteAnalytics(jsonData);			
	} catch(ex) {
		//dothing
	}
}
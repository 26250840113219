const vars = require('./Const');
export class TranslationUtil {
    static GetDefinition(lang="en", module="search") {
        if(!lang) {
            lang = "en";
        } else if(lang.split("-")[0] === "zh") {
            lang = "zh-cn";
        } else {
            lang = lang.split("-")[0];
        }
        return new Promise((res, rej) => {
            import(`../translations/${module}/${lang}.json`).then((data) => {
                let resp = {};
                if(data && data.default) {
                    resp = data.default;
                }
                res(resp);
            }).catch(err => {
                res({})
            });
        });    
    }
    static isEnglish(lang) {
        return lang.startsWith("en");
    }
    static GetLocale(lang, countryCode) {
        return `${lang?.split('-')[0]}-${countryCode === "Unknown" || !countryCode ? "US" : countryCode}`;
    }
    static GetSupportedLangFromLang(lang) {
        let supportedLang = "";
        if(lang) {
            lang = lang.toLowerCase();
            let splitLang = lang.split("-")[0];
            if(vars.supportedLanguages[lang]) {
                supportedLang = lang
            } else if(vars.supportedLanguages[splitLang]) {
                supportedLang = splitLang
            }
        }
        return supportedLang;
    }
    static GetSupportedLanguage() {
        if(window.ntGlobalLang) {
            return window.ntGlobalLang;
        }
        let supportedLang = TranslationUtil.GetSupportedLangFromLang(navigator.language || navigator.userLanguage);
        if(!supportedLang) {
            let languages = (navigator && navigator.languages) ? navigator.languages : [];
            for(let ix in languages) {
                supportedLang = TranslationUtil.GetSupportedLangFromLang(languages[ix]);
                if(supportedLang) {
                    break;
                }
            }
        }
        if(!supportedLang) {
            supportedLang = "en";
        }
        return supportedLang;
    }
}

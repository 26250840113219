import { h} from 'preact';
import { useState, useEffect  } from 'preact/hooks';
import { IntlProvider, Text } from 'preact-i18n';
import { TranslationUtil } from '../utils/TranslationUtil';

import "./header.scss";

export const Header = props => {
    const [definition, setDefinition] = useState({})
    useEffect(() => {
        const translateLang = props.lang ? props.lang : TranslationUtil.GetSupportedLanguage(); // use props.lang if avaialable
        TranslationUtil.GetDefinition(translateLang, "header").then((def) => {
            setDefinition(def);
        }).catch();
    }, []);

    return (
        <IntlProvider definition={definition}>
            <div className="page-header">
                <div className="content-header">
                    <a href="/"><img src='/images/Browse_logo_with_icon_light_bg.svg'/></a>
                    <div>
                        <a href="#onlineSafetyBlock"><Text id="header.onlineSafety">Online Safety</Text></a> 
                        <a href="#aboutUsBlock"><Text id="header.aboutUs">About Us</Text></a>
                        <a href="/blog"><Text id="header.blog">Blog</Text></a>
                        <a href="/support"><Text id="header.support">Support</Text></a>
                    </div>
                </div>
            </div>
        </IntlProvider>
    )
}

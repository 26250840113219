//24 should be divisible by countOf(v_1_cls)*countOf(v_2_cls)*countOf(v_3_cls)
const isExpRunning = false;
const expName = "Images-Words-230124"; // Name-yymmdd
const v_1_cls = {
	"Original": "",
	"NewAboveButtonWords": "new-above-button-words"
}
const v_2_cls = {
	"None": "",
	"NewBlueAreaWords": "new-blue-area-words"
}
const v_3_cls = {
	"None": "",
	"NewBenefitImagesOrder": "new-benefits-images-order"
}
const totalVariations = Object.keys(v_1_cls).length * Object.keys(v_2_cls).length * Object.keys(v_3_cls).length;

export const getLPExperimentInfo = (expId) => {
	try {
		let expDetails = "";
		let classes = [];
		// if(!isExpRunning || !document.body.hasAttribute("utm_x"))
		if(!isExpRunning)
		{
			return {name: expName, isExpRunning: isExpRunning, details: expDetails, classes: classes};
		}
        if(!expId || isNaN(expId)) {
            expId = 0;
        }
        expId = parseInt(expId);
		let bId = expId % totalVariations;
		for(let key1 in v_1_cls) {
			if(expDetails) break;
			for(let key2 in v_2_cls) {
				if(expDetails) break;
				for(let key3 in v_3_cls) {
					if(bId === 0) {
						expDetails = `${key1},${key2},${key3}`;
						classes.push(v_1_cls[key1], v_2_cls[key2], v_3_cls[key3]);
						break;
					}
					bId--;
				}
			}
		}
        //console.log("Experiment Name", expName);
		return {name: expName, isExpRunning: isExpRunning, details: expDetails, classes: classes};
	} catch(err) {
		//do nothing
	}
    return {name: expName, isExpRunning: isExpRunning, details: expDetails, classes: classes};
}

export const runLPExperiment = (expId) => {
	try {
		if(document.body.hasAttribute("utm_x") && expId > -1) {
			let classes = getLPExperimentInfo(expId).classes;
			classes.map(cls => {
				if(cls && !document.body.classList.contains(cls)) {
					document.body.classList.add(cls);
				}
			})
		}
	} catch(err) {
		//console.log("Error happened in parsing getUtmX", err);
	}
}


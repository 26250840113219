export class WidgetHelper {
    static SetParam(paramName, paramValue, dispatchEvent=true) {
        let oldVal = WidgetHelper.GetParam(paramName);
        if(oldVal === paramValue) {
            return;            
        }
        window._aeBlurWidgetParams = window._aeBlurWidgetParams || {};
        window._aeBlurWidgetParams[paramName] = paramValue;
        //raise an event for this
        if(dispatchEvent) {
            WidgetHelper.DispatchParamEvent();
        }
    }
    static GetParams() {
        let sParams = window._aeBlurWidgetParams || {};
        let params = {};
        for(var key in sParams) {
            params[key] = sParams[key];
        }
        return params;
    }
    static GetParam(paramKey) {
        let sParams = window._aeBlurWidgetParams || {};
        if(sParams[paramKey]) {
            return sParams[paramKey];
        }
        return "";
    }
    static DispatchParamEvent() {
        const myEvent = new Event('aeBlurWidgetParamsUpdate');
        document.dispatchEvent(myEvent);
    }
    static DispatchLocationEvent() {
        const myEvent = new Event('aeBlurWidgeLocationUpdate');
        document.dispatchEvent(myEvent);
    }
    static RegisterForParamUpdate(cb) {
        if(cb !== null) {
            document.addEventListener('aeBlurWidgetParamsUpdate', (e) => {
                cb();
            })
        }
    }
    static RegisterForLocationUpdate(cb) {
        if(cb !== null) {
            document.addEventListener('aeBlurWidgeLocationUpdate', (e) => {
                cb();
            })
        }
    }
    static EscapeQuotes(text) {
        return text.replace(/'/g, "&apos;").replace(/"/g, "&quot;");
    }
    static showHideLoading(isLoading, loadingText="") {
        let loadingDivId = "loadingDiv";
        let loadingObj = document.getElementById(loadingDivId);
        if(loadingObj) {
            loadingObj.style.display = isLoading ? "block": "none";
        }
        if(loadingText && document.getElementById("loadingDivText")) {
            document.getElementById("loadingDivText").innerText = loadingText;
        }
    }
    static IsKnownParam(pName) {
        let knownParams = [
            "q",
            "page",
            "service",
            "affid",      
            "ss",
            "testflag"
        ]
        if(knownParams.indexOf(pName.toLowerCase()) > -1) {
            return true;
        }     
        return false;
    }
    static IsKnownAttribute(attrName) {
        if(attrName === "ae-wdg-status") {
            return true;
        }
        return false;
    }
}

import Bowser from "bowser";
export class DetectBrowser {
    static getBrowserName() {
        return Bowser.getParser(navigator.userAgent).getBrowserName();
    }
    static getPlatformType() {
        return Bowser.getParser(navigator.userAgent).getPlatformType();
    }
    static getBrowser() {   // WhatAmI Format
        const ua = Bowser.getParser(navigator.userAgent);
        let name = ua.getBrowserName();
        if (name == "Microsoft Edge") {    // Edge detected on Bowser:  "Microsoft Edge"
            name = "EdgeChromium";         // Edge detected on WhatAmI: "EdgeChromium"
        }
        return `${name},${ua.getBrowserVersion()}`;
    }
    static isBrowserSupported(browserName="", platform="") {  // for landing page and installAd
        browserName = browserName ? browserName : DetectBrowser.getBrowserName();
        platform = platform ? platform : DetectBrowser.getPlatformType();
        if( browserName.indexOf("Firefox") === -1 &&
            browserName.indexOf("Microsoft Edge") === -1 &&
            browserName.indexOf("Chrome") === -1 ) {
            return false;
        }
        if(platform !== Bowser.PLATFORMS_MAP.desktop) {
            if(browserName.indexOf("Firefox") === -1) {
                return false;
            }
        }
        return true;
    }
}

import { DetectBrowser } from "./DetectBrowser";

const CHROME_SEARCH_EXT_ID = "lcaemiijkgjlahgmkkeofenlcpkbgoie";
const EDGE_SEARCH_EXT_ID = "gjmhpmpopjbdkjgihjnhnijmedhgchel";
const DEV_SEARCH_EXT_ID = "miafmopidplfjcckaanigjeffffoohmc";

const CHROME_ADCONTROL_EXT_ID = "lhjgfbdmhhdmjnnjeabjclblkecbldpd";
const EDGE_ADCONTROL_EXT_ID = "honofnengibjlpaeookeoldjfaniedil";

const CHROME_WEBSAFETY_EXT_ID = "ghjakbehlhkjdiehaeffedomkakcenjo";
const EDGE_WEBSAFETY_EXT_ID = "eelchcnolfmggobnmfpmhnicpffodefe";
const DEV_WEBSAFETY_EXT_ID = "aakfempjamfiobfjnmfbgnbgndhiijdb";

export class ExtensionUtil {
    static isBrowseInstalled(browserName = "") {
        browserName = browserName ? browserName : DetectBrowser.getBrowserName();
        let promise = new Promise( (resolve, reject) => {
            if (browserName === "Firefox") {
                try {
                    var extInfo = document.getElementById("browseLiveSearchExt");
                    if(extInfo && extInfo.innerHTML !=="") {
                        resolve(true);
                    } else {
                        reject(false); //for firefox we are not sure yet
                    }
                } catch(err) {
                    reject(false);
                }
            } else {
                let extensionId = (browserName === "Microsoft Edge") ? EDGE_SEARCH_EXT_ID : CHROME_SEARCH_EXT_ID;
                if(chrome && chrome.runtime && chrome.runtime.sendMessage) {
                    try {
                        chrome.runtime.sendMessage(extensionId, {type: "GetBrowseInfo"}, resp => {
                            if(chrome.runtime.lastError) {
                                resolve(false);
                            } 
                            if (resp && resp.browseVersion && resp.browseVersion !="") {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    } catch(err) {
                        resolve(false);
                    }
                } else {
                    resolve(false); //this means its not installed
                }
            }
        });
        return promise;
    }
    static isWebSafetyInstalled(browserName = "") {
        browserName = browserName ? browserName : DetectBrowser.getBrowserName();
        let promise = new Promise( (resolve, reject) => {
            if (browserName === "Firefox") {
                try {
                    var extInfo = document.getElementById("bl-websafety-ext-installed");
                    if(extInfo && extInfo.innerHTML !=="") {
                        resolve(true);
                    } else {
                        reject(false); //for firefox we are not sure yet
                    }
                } catch(err) {
                    reject(false);
                }
            } else {
                let extensionId = (browserName === "Microsoft Edge") ? EDGE_WEBSAFETY_EXT_ID : CHROME_WEBSAFETY_EXT_ID;
                if(chrome && chrome.runtime && chrome.runtime.sendMessage) {
                    try {
                        chrome.runtime.sendMessage(extensionId, {type: "GetBrowseInfo"}, resp => {
                            if(chrome.runtime.lastError) {
                                resolve(false);
                            } 
                            if (resp && resp.extVersion && resp.extVersion !="") {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    } catch(err) {
                        resolve(false);
                    }
                } else {
                    resolve(false); //this means its not installed
                }
            }
        });
        return promise;
    }    
    static SetSearchSetting() {
        if(typeof chrome !== 'undefined' && chrome && chrome.runtime && chrome.runtime.sendMessage) {
            let searchSetting = ExtensionUtil.GetSearchSetting();
            let extIds = [CHROME_SEARCH_EXT_ID, EDGE_SEARCH_EXT_ID, DEV_SEARCH_EXT_ID]
            for(let ix in extIds) {
                try {
                    chrome.runtime.sendMessage(extIds[ix], {type: "SetSearchSetting", searchSetting: searchSetting});    
                } catch(err) {
                    //do nothing here
                }
            }
        }
    }
    static GetStoreLink(browserName="", lang="", source="", medium="", campaign="", productName="Search") {
        browserName = browserName ? browserName : DetectBrowser.getBrowserName();
        lang = lang ? lang : "en";
        let storeLink = `https://chrome.google.com/webstore/detail/browselive-ad-control/${CHROME_ADCONTROL_EXT_ID}?hl=${lang}`;
        if(productName === "Search") {
            storeLink = `https://chrome.google.com/webstore/detail/browselive-search/${CHROME_SEARCH_EXT_ID}?hl=${lang}`;
        } else if(productName === "WebSafety") {
            storeLink = `https://chrome.google.com/webstore/detail/browselive-web-safety/${CHROME_WEBSAFETY_EXT_ID}?hl=${lang}`;
        }
        let joinChar = '&';
        if (browserName === "Firefox") {
            joinChar = '?';
            storeLink = "https://addons.mozilla.org/firefox/downloads/latest/browse-live/addon-3936340-latest.xpi";
            if(productName === "Search") {
                storeLink = "https://addons.mozilla.org/firefox/downloads/latest/browse-live-search/addon-4010240-latest.xpi"
            }else if(productName === "WebSafety") {
                storeLink = "https://addons.mozilla.org/firefox/downloads/latest/browse-live-websafety/addon-3903012-latest.xpi";
            }
        } else if (browserName === "Microsoft Edge") {
            joinChar = '?';
            storeLink = `https://microsoftedge.microsoft.com/addons/detail/browselive-ad-control/${EDGE_ADCONTROL_EXT_ID}`;
            if(productName === "Search") {
                storeLink = `https://microsoftedge.microsoft.com/addons/detail/browselive-search/${EDGE_SEARCH_EXT_ID}`;
            }else if(productName === "WebSafety") {
                joinChar = '&';
                storeLink = `https://microsoftedge.microsoft.com/addons/detail/browselive-web-safety/${EDGE_WEBSAFETY_EXT_ID}?hl=${lang}`;
            }
        }
        const parameters = new URLSearchParams(window.location.search);
        let insert_utm = true;
        parameters.forEach((val, key) => {
            if(campaign === "lp" && key.toLowerCase().indexOf("utm_") > -1) {
                insert_utm = false;
            }
            if(campaign !== "lp" && key.toLowerCase().indexOf("utm_") > -1) {
                return; //dont add url utm in the link, if its not lp
            }
            storeLink += `${joinChar}${key}=${val}`;
            joinChar = '&';
        })
        if(insert_utm) {
            storeLink += `${joinChar}utm_source=${source}`;
            joinChar = '&';
            storeLink += `${joinChar}utm_medium=${medium}`;
            storeLink += `${joinChar}utm_campaign=${campaign}`;
        }
        return storeLink;
    }

    static GetStoreLinkOpenTarget(browserName = "") {
        browserName = browserName ? browserName : DetectBrowser.getBrowserName();
        if (browserName === "Firefox") {
            return "_self";
        } else {
            return "_blank";
        }
    }

    static GetBrowserStoreText(browserName="") {
        browserName = browserName ? browserName : DetectBrowser.getBrowserName();
        let browserStoreText = "Chrome Web Store"; // default to chrome
        if (browserName === "Firefox") {
            browserStoreText ="Firefox Add-ons";
        } else if (browserName === "Microsoft Edge") {
            browserStoreText ="Microsoft Edge Addons";
        }    
        return browserStoreText;
    }

    static GetBrowserStoreImage(browserName="") {
        browserName = browserName ? browserName : DetectBrowser.getBrowserName();
        let browserStoreImage = "/images/browser/chrome-logo.svg";
        if (browserName === "Firefox") {
            browserStoreImage = "/images/browser/firefox-logo.svg";
        } else if (browserName === "Microsoft Edge") {
            browserStoreImage = "/images/browser/edgeChromium-logo.png";
        }    
        return browserStoreImage;
    }

    static GetSearchSetting = () => {
        let safeSearchLevel = "";
        let toggledSetting = window.localStorage.getItem('safeSearchToggled'); //return null if it dont exist
        toggledSetting = (toggledSetting == 'true') ? true : false;
        let levelSetting = window.localStorage.getItem('safeSearchLevel'); //return null if it dont exist
        if(levelSetting) {
          safeSearchLevel = levelSetting;
        } else {
          safeSearchLevel = toggledSetting ? "strict" : "none";
        }
  
        let themeMode = window.localStorage.getItem('isDarkTheme'); //return null if it dont exist
        if(themeMode !== null) {
          themeMode = themeMode === 'true' ? "dark" : "light";
        } else {
          themeMode = "system";
        }
        let currentLocation = window.localStorage.getItem('mycurrentlocation');
        let currentLocationName = window.localStorage.getItem('mycurrentlocationname');
        let currentCountryCode = window.localStorage.getItem('mycurrentcountrycode');
        let affiliateId = window.localStorage.getItem('affiliateId');
        let searchSetting = {
            ssLevel: safeSearchLevel,
            themeMode: themeMode,
            currentLocation: currentLocation,
            currentLocationName: currentLocationName,
            currentCountryCode: currentCountryCode,
            affiliateId: affiliateId
        }
        return searchSetting;
      } 
}

const isDev = false;
const isApiDev = false;
const isApiStaging = false;

const baseBrowseUrl = (isApiStaging) ? "https://staging-dot-blur-live-project.appspot.com" : (isApiDev) ? "http://localhost:5001": "https://anonymous.browse.live";
const baseBlurUrl = (isApiStaging) ? "https://staging-dot-blur-live-project.appspot.com" : (isApiDev) ? "http://localhost:5001": "https://anonymous.blur.live";

const baseBrowseUIUrl = (isDev) ? "http://localhost:4000" : "https://browse.live";
const baseBlurUIUrl = (isDev) ? "http://localhost:4000": "https://search.blur.live";
class ApiUtil {

}
export const SetBaseUrl = (isBrowseLive) => {
    if(isBrowseLive) {
        ApiUtil.BaseUrl = baseBrowseUrl;
        ApiUtil.BaseUIUrl = baseBrowseUIUrl;
    } else {
        ApiUtil.BaseUrl = baseBlurUrl;
        ApiUtil.BaseUIUrl = baseBlurUIUrl;
    }
}
export const GetBaseUrl = () => {
    if(ApiUtil.BaseUrl) {
        return ApiUtil.BaseUrl;
    } else {
        return baseBrowseUrl;
    }
}
export const GetBaseUIUrl = () => {
    if(ApiUtil.BaseUIUrl) {
        return ApiUtil.BaseUIUrl;
    } else {
        return baseBrowseUIUrl;
    }
}
export const GetApiEndpoint=() => {return `${GetBaseUrl()}`};
export const GetWhatAmIEndpoint=() => {return `${GetBaseUrl()}/license/whatami`};
export const GetSearchEndPoint=() => {return `${GetBaseUrl()}/widget/search`};
export const GetAffSearchEndPoint=() => {return `${GetBaseUrl()}/widget/affsearch`};
export const GetWeatherEndPoint=() => {return `${GetBaseUrl()}/widget/weather`};
export const GetGeoLookupEndPoint=() => {return `${GetBaseUrl()}/widget/geolookup`};
export const GetLatlonInfoEndPoint=() => {return `${GetBaseUrl()}/widget/latloninfo`};
export const GetTranslationURL=() => {return `${GetBaseUrl()}/blur/translate`};
export const GetTranslationWithoutTextURL=() => {return `${GetBaseUrl()}/blur/translateTitles`};
export const GetCheckTextURL=() => {return `${GetBaseUrl()}/blur/hasText`};
export const GetSupportURL=() => {return `${GetBaseUrl()}/blur/contact`};
export const GetWhoAmI=() => {return `https://api.blur.live/license/whoami`}
//for where to take search to
export const GetSearchUIUrl=() => {return `${GetBaseUIUrl()}/search`};
export const GetBrowseLiteUrl=() => {return `${GetBaseUIUrl()}/browselite`};

export const GetSafeImageUrl = (str) => {
    if(str) {
        let loc = str.indexOf("://");
        if(loc > 0) {
            let protocol = str.substring(0, loc);
            let rest = str.substring(loc+3);
            return `${GetBaseUrl()}/widget/saferlink/${protocol}/${rest}`
        }
    }
    return '';
  }

  export const GetSaferShoppingUrl = (str, affId="", position="") => {
    if(str) {
      return `${GetBaseUrl()}/widget/safershopping?uri=${encodeURIComponent(str)}&affid=${affId}&pos=${position}`;
    }
    return '';
  }

  //escaping query
  const SpecialChars = {
    "$": "%24",
    "&": "%26",
    "+": "%2B",
    ",": "%2C",
    "/": "%2F",
    ":": "%3A",
    ";": "%3B",
    "=": "%3D",
    "?": "%3F",
    "@": "%40",
    "!": "%21",
    "#": "%23",
    "{": "%7B",
    "}": "%7D",
    "|": "%7C",
    "\\": "%5C",
    "^": "%5E",
    "[": "%5B",
    "]": "%5D",
    "`": "%60"
  }
  
  const entityMap = {
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
    '...': '&hellip;',
    '&': '&amp;'
  };
  
  export const UnEscapeHtml = (str) => {
      try {
        let toRet = str;
        for(var key in entityMap) {
            toRet = toRet.replaceAll(entityMap[key], key);
        }
        return toRet;
      } catch(ex) {
          return str;
      }
  }
  export const EscapeQuery = (query) => {
    //special case
    query = query.replaceAll("%", "%25");
    for(var char in SpecialChars) {
      query = query.replaceAll(char, SpecialChars[char]);
    }
    return query;
  }
  export const UnescapeQuery = (query) => {
    for(var char in SpecialChars) {
      query = query.replaceAll(SpecialChars[char], char);
    }
    //special case
    query = query.replaceAll("%25", "%");
    return query;
  }

  export const GetSetAffiliateId = () => {
    let exAffId = window.localStorage.getItem('affiliateId')
    if(exAffId) {
      return exAffId;
    }
    const parameters = new URLSearchParams(window.location.search);
    if(parameters && parameters.has("affid")) {
      let affId = parameters.get("affid");
      if(affId) {
        window.localStorage.setItem('affiliateId', affId);
        return affId;
      }
    }
    if(parameters && parameters.has("affiliate") && parameters.has("subscription")) {
      let affId = parameters.get("affiliate");
      if(affId) {
        window.localStorage.setItem('affiliateId', affId);
        return affId;
      }
    }
    //we reached here that mean affId is not set and is not present in url
    //we will mark ourselves as affId
    window.localStorage.setItem('affiliateId', "appesteem.com");
    return "appesteem.com";
  }
